/*
Font weight, style and decoration can not be used for the highlighted search term using conventional methods. Using fully-wired tpa-settings and stylable it is only possible to have font as a whole css shorthand, which includes not only decorations but also a font value and its size. When font value and size is present, the font of highlighted text is overwritten. It causes problems because highlights can be both in the title and description areas, which have different fonts/sizes. Therefore the solution is to use font type provided by the tpa-settings and manually extract and set the specific font properties.
*/

export interface HiglightStyle {
  bold: boolean;
  italic: boolean;
  underline: boolean;
}

export const addHighlightStyle = (
  str: string,
  highlightStyle: HiglightStyle,
): string => {
  const { bold, italic, underline } = highlightStyle;
  let style = '';

  if (!bold && !italic && !underline) {
    return str;
  }

  if (bold) {
    style += 'font-weight:bold;';
  }

  if (italic) {
    style += 'font-style:italic;';
  }

  if (underline) {
    style += 'text-decoration:underline;';
  }

  return str.replace(new RegExp('<mark>', 'g'), `<mark style="${style}">`);
};
